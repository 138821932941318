import { ArrowBack, ExitToAppOutlined, Info, Menu } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/logo.png";
import { clearAll, setPhoneNo } from "../../reducers/appointmentInfoSlice";
import CustomModal from "../CustomModal/CustomModal";
import { useState } from "react";
import SignedInAs from "../SignedIn/SignedIn";
import { signOut } from "../../reducers/signInSlice";
import { useNavigate } from "react-router-dom";

const Titlebar = ({ title, backClickAction, onMenuClick }) => {
  const activeStep = useSelector((state) => state.appointmentInfo.step);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const patientMobileNo = useSelector((state) => state.appointmentInfo.phoneNo);
  const signedInPatient = useSelector((state) => state.signIn);
  const dispatch = useDispatch();
  const logoHeight = isMobile ? "35px" : "45px";
  const logoWidth = isMobile ? "45px" : "45px";

  const [showLoggedOutModal, setShowLoggedOutModal] = useState(false);
  const navigate = useNavigate();

  return (
    <div>
      <CustomModal
        open={showLoggedOutModal}
        sx={{
          width: "400px",
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
          gap={2}
        >
          <Info sx={{ fontSize: "5rem", color: theme.palette.primary.main }} />
          <Typography textAlign={"center"}>
            Signed out successfully. <br />
            You will be signed in automatically once mobile number is verified.
          </Typography>
        </Box>
        <Box display={"flex"} justifyContent={"center"} mt={2}>
          <Button
            onClick={() => setShowLoggedOutModal(false)}
            variant="outlined"
          >
            Okay
          </Button>
        </Box>
      </CustomModal>
      <Box display={"flex"} alignItems="center">
        {activeStep !== 0 && (
          <span onClick={backClickAction}>
            <ArrowBack sx={{ mt: { xs: 1, sm: 0, md: 1 }, mr: 1 }} />
          </span>
        )}
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={onMenuClick}
        >
          <img src={logo} alt="logo" height={logoHeight} width={logoWidth} style={{
            marginRight: isMobile ? "30px" : "50px"
          }}/>
        </div>
        <Box display={"flex"} justifyContent={"center"} flexGrow={1}>
          <Typography
            width={"100%"}
            fontWeight={"bold"}
            align="center"
            sx={{
              fontSize: { xs: "1rem", sm: "1.5rem", lg: "1.5rem" },
            }}
          >
            {title}
          </Typography>
        </Box>
        <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          {signedInPatient.Id !== "" && !isMobile && (
            <SignedInAs
              patientName={signedInPatient.FirstName}
              onClick={() => {
                navigate("/select-patient");
              }}
            />
          )}
          {signedInPatient.Id !== "" ? (
            <IconButton
              sx={{
                minWidth: 0,
                p: 0,
              }}
              title="Sign out"
              onClick={() => {
                dispatch(setPhoneNo({ phoneNo: "" }));
                dispatch(clearAll());
                dispatch(signOut());
                setShowLoggedOutModal(true);
              }}
            >
              <ExitToAppOutlined sx={{ fontSize: "1.5rem" }} />
            </IconButton>
          ) : (
            <Button
              onClick={() => {
                navigate("/sign-in");
              }}
            >
              Sign in
            </Button>
          )}
          <IconButton
            sx={{
              minWidth: 0,
              p: 0,
            }}
            title="Menu"
            onClick={onMenuClick}
          >
            <Menu sx={{ fontSize: "1.5rem" }} />
          </IconButton>
        </div>
      </Box>
    </div>
  );
};

export default Titlebar;
